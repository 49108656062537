import icon from "./assets/images/icon.png";
import badge_appstore from "./assets/images/badge_appstore.svg";

function Home() {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-slate-200 bg-gradient-to-b from-slate-100 to-violet-200">
      <div className="w-72 h-72 p-5 mt-[-100px]">
        <img src={icon} alt="Replidoc Icon" />
      </div>

      <div className="p-5">
        <h1 className="font-bold text-center text-3xl">Replidoc</h1>
        <h2 className="text-center text-xl text-slate-500">
          Healthcare. Powered by AI. Chat now.
        </h2>
      </div>

      <div>
        <a href="https://apple.com">
          <img
            src={badge_appstore}
            alt="Download on the App Store"
            width={200}
          />
        </a>
      </div>
    </div>
  );
}

export default Home;
