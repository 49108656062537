import icon from "./assets/images/icon.png";

function Success() {
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-slate-200 bg-gradient-to-b from-slate-100 to-violet-200">
      <div className="w-72 h-72 p-5 mt-[-100px]">
        <img src={icon} alt="Replidoc Icon" />
      </div>

      <div className="p-5">
        <h1 className="font-bold text-center text-3xl">Account confirmed</h1>
        <h2 className="text-center text-xl text-slate-500">
          Please return to the Replidoc app and log in.
        </h2>
      </div>
    </div>
  );
}

export default Success;
